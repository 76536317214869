import React from 'react'

import FormComponent from './Form'
import { defaultSettings } from './defaults/settings'

const createPlugin = (settings) => {
    const mergedSettings = { ...defaultSettings, ...settings }

    const { Component } = mergedSettings

    return {
        Component: (props) => <Component {...props} {...mergedSettings} />,
        FormComponent: (props) => <FormComponent {...props} {...mergedSettings} />,

        name: 'peracto/layout/container',
        version: '1.0.0',
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,

        createInitialChildren: () => [
            [
                {
                    content: {
                        plugin: mergedSettings.defaultPlugin,
                        state: {
                            text: `<h3 style="text-align:center;">Edit me!</h3>
                                <p style="text-align:center;">
                                    This is your default content block. You can update this content or replace it with another block.
                                </p>
                            `,
                        },
                    },
                },
            ],
        ],
    }
}

export default createPlugin
