import {
    Accordion,
    Carousel,
    ProductsCarousel,
    MenuItemsCarousel
} from "@peracto/peracto-blocks-tailwind";

import Container from './Container'

export default {
    plugins: {
        content: [Carousel, Accordion, ProductsCarousel, MenuItemsCarousel],
        layout: [Container],
    },
};
