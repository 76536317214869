import createPlugin from './createPlugin'

import { default as ContainerComponent } from './Component'

const containerPlugin = settings =>
    createPlugin({
        Component: ContainerComponent,
        ...settings,
    })

const container = containerPlugin()
export default container

export { containerPlugin, ContainerComponent }
