import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";

import { EditorTheme, AdminTheme } from "./theme";
import { buttonVariants } from "./theme/buttonVariants";

import { colors, fonts } from "./theme/tailwind";

import blocks from "./blocks";
import { menu } from "./menu";

import compact from "lodash/compact";
import startCase from "lodash/startCase";
import './styles/globals.scss'

const config = new Config({
  api: process.env.REACT_APP_API,
  algolia: {
    applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
  },
  modules: [
    PeractoUser(),
    PeractoCommon(),
    PeractoCatalogue(),
    PeractoLocations(),
    PeractoPromotions(),
    PeractoContent(),
  ],
  editorConfig: {
    theme: EditorTheme,
    screens: {
      sm: "100%",
      md: "100%",
      lg: "100%",
      xl: "1360px",
    },
    colorOptions: compact(
      Object.entries(colors).map(([label, value]) => {
        return {
          label: startCase(label.replaceAll("brand-", "")),
          value,
        };
      })
    ),
    fonts,
    locales: ["en_GB", "es_ES", "fr_FR"],
    blocks,
    isTailwind: true,
    buttonVariants,
    inputTypes: {},
    stylesheets: [
      "https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap",
    ],
  },
  features: {
    dashboard: {
      tables: true,
    },
    shippingServices: {
      deliveryDays: true,
      conditions: true,
    },
    products: {
      bulkPricing: true,
      exportProductData: true,
      productVariants: true,
      productMenuItemRelations: true,
    },
    content: {
      indexContent: true,
      productRelationships: {
        article: true,
        page: true,
        template: true,
        element: true,
      },
    },
    categories: {
      exportCategoryData: true,
    },
    user: {
      companySettings: true,
      userSettings: true,
      exportUserData: true,
      userGroups: true,
    },
    customerGroups: {
      accessAllQuotes: true,
    },
    orders: {
      pickLists: true,
      // pickListsPDF: {
      // updateStatusToPicking: true,
      // logo: '',
      // headerText: '',
      // },
      createOrders: true,
      exportOrderData: true,
      orderHistory: true,
      resendOrders: true,
      resendConfirmationEmails: true,
    },
  },
  menu,
});

const App = () => (
  <ThemeProvider theme={AdminTheme}>
    <Peracto config={config} />
  </ThemeProvider>
);

export default App;
