const { darken, lighten } = require('polished')

// Used so hover colors get automatically updated
const brandPrimaryColor = '#00893E'
const brandSecondaryColor = '#0564C2'
const brandBlackColor = '#222222'
const brandGreyColor = '#D8D8D8'
const brandWhiteColor = '#FFFFFF'

const colors = {
    // Brand & Accent
    'brand-primary': brandPrimaryColor,
    'brand-secondary': brandSecondaryColor,

    // Base
    'brand-black': brandBlackColor,
    'brand-dark-grey': '#505050',
    'brand-grey': brandGreyColor,
    'brand-light-grey': '#F2F2F2',
    'brand-white': brandWhiteColor,

    // Messages
    'brand-success': '#2DB200',
    'brand-warning': '#F49C10',
    'brand-danger': '#E30613',
    'brand-info': '#0697E3',

    // Alerts
    'brand-success-alert': '#E9F7E5',
    'brand-warning-alert': '#FEF5E6',
    'brand-danger-alert': '#FCE5E7',
    'brand-info-alert': '#E5F4FC',

    // Alert Borders
    'brand-success-alert-border': '#D5F0CE',
    'brand-warning-alert-border': '#FDECD0',
    'brand-danger-alert-border': '#F9CED2',
    'brand-info-alert-border': '#CEEAF9',

    // Checkbox Count
    'brand-checkbox-count': '#909090',

    // Lighten & Darken
    'brand-primary-lighten': lighten(0.05, brandPrimaryColor),
    'brand-primary-darken': darken(0.05, brandPrimaryColor),

    'brand-secondary-lighten': lighten(0.05, brandSecondaryColor),
    'brand-secondary-darken': darken(0.05, brandSecondaryColor),

    'brand-black-lighten': lighten(0.05, brandBlackColor),
    'brand-black-darken': darken(0.05, brandBlackColor),

    'brand-grey-lighten': lighten(0.05, brandGreyColor),
    'brand-grey-darken': darken(0.05, brandGreyColor),

    'brand-white-lighten': lighten(0.05, brandWhiteColor),
    'brand-white-darken': darken(0.05, brandWhiteColor),
}

const fonts = {
    heading: ['Mulish, sans-serif'],
    body: ['Mulish, sans-serif'],
}

module.exports = { colors, fonts }
