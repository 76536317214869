import { theme } from "@chakra-ui/core";

// [container, breakpoint]
const sizes = {
    sm: ["100%", "576px"],
    md: ["100%", "768px"],
    lg: ["100%", "992px"],
    xl: ["1360px", "1280px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brand: {
            white: "#FFFFFF",
            black: "#000000",
            grey: "#f0f0f0",
            darkGrey: "#666666",
            blue: "#140F64",
            red: "#E11414",
            redLight: "#db5858",
            paleRed: "#FDF1F2",
        },
    },
    fonts: {
        ...theme.fonts,
        heading: "Mulish, sans-serif",
        body: "Mulish, sans-serif",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};